import React, { useState, useEffect } from "react";
import axios from "axios";
import { searchParams, useSearchParams, useNavigate } from "react-router-dom";
import { toaster } from "evergreen-ui";

export default function Active(props) {
  let navigate = useNavigate();
  const [verifyCode, setVerifyCode] = useState("");
  const [loading, setLoading] = useState("");
  let [searchParams, setSearchParams] = useSearchParams();
  let email = searchParams.get("email") || "";

  function PostVerify() {
    // console.log(`${email} - ${verifyCode}`);
    setLoading("is-loading");
    let data = { code: verifyCode, email: email };
    axios
      .post("/api/active", data)
      .then((response) => {
        setLoading("");
        // console.log(response);
        if (response["status"] === 200) {
          toaster.success(response["msg"], { duration: 2 });
          let timerId = setInterval(() => {
            navigate(`/login`);
            clearInterval(timerId);
          }, 2000);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading("");
      });
  }
  return (
    <React.Fragment>
      <section
        className="hero is-fullheight"
        style={{
          // sea weed
          background:
            "linear-gradient(-225deg, #22E1FF 0%, #1D8FE1 48%, #625EB1 100%)",
        }}
      >
        <div className="hero-body">
          <div className="container">
            <div className="columns is-centered">
              <div className="column is-4">
                <div className="field">
                  <label className="label has-text-white">
                    Enter the code that you received
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      type="text"
                      placeholder="Input verify code"
                      onChange={(e) => {
                        setVerifyCode(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="field">
                  <div className="control">
                    <button
                      onClick={PostVerify}
                      className={`button is-link  is-rounded is-fullwidth ${loading}`}
                    >
                      Verify
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
