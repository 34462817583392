import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { toaster } from "evergreen-ui";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";

export default function SignUp(props) {
  let navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState("");

  useEffect(function (props) {
    document.title = "SignUp";
  });
  function SignUp(e) {
    // console.log(`email=${email}, password= ${password}`);
    setLoading("is-loading");
    let data = { email: email, password: password };
    axios
      .post("/api/sign/up", data)
      .then(function (response) {
        setLoading("");
        // console.log(response);
        if (response["status"] === 200) {
          toaster.success(response["msg"], { duration: 2 });
          let timerId = setInterval(() => {
            navigate(`/active?email=${email}`);
            clearInterval(timerId);
          }, 2000);
        }
      })
      .catch((error) => {
        setLoading("");
      });
  }

  return (
    <React.Fragment>
      <section
        className="hero is-fullheight"
        style={{
          // 绿色 green beach
          //   background: "linear-gradient(to right, #02aab0, #00cdac)",
          //   emerald water
          //   background: "linear-gradient(to right, #348f50, #56b4d3)",
          //   mojito
          //   background: "linear-gradient(to right, #1d976c, #93f9b9)",
          // sea weed
          background:
            "linear-gradient(-225deg, #AC32E4 0%, #7918F2 48%, #4801FF 100%)",
          // background-image:
          //   background: "linear-gradient(to top, #7028e4 0%, #e5b2ca 100%)",
          //   sea blizz
          //   background: "linear-gradient(to right, #1cd8d2, #93edc7)",
          //   蓝色 skyline
          //   background: "linear-gradient(to right, #1488cc, #2b32b2)",
        }}
      >
        <div className="hero-body">
          <div className="container">
            <div className="columns is-centered">
              <div className="column is-5-tablet is-4-desktop is-3-widescreen">
                <div className="box">
                  <div className="field">
                    <label className="label">Email</label>
                    <div className="control has-icons-left">
                      <input
                        className="input"
                        type="text"
                        placeholder="your email"
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                      <span className="icon is-small is-left">
                        <EmailIcon />
                      </span>
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Password</label>
                    <div className="control has-icons-left">
                      <input
                        className="input"
                        type="password"
                        placeholder="your password"
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                      />
                      <span className="icon is-small is-left">
                        <LockIcon />
                      </span>
                    </div>
                  </div>
                  <div className="field">
                    <button
                      className={`button is-rounded is-fullwidth ${loading}`}
                      onClick={SignUp}
                      style={{
                        background:
                          "linear-gradient(to right, #DA22FF 0%, #9733EE 0%, #DA22FF  100%)",
                        color: "white",
                      }}
                    >
                      Sign up
                    </button>
                    <div className="level">
                      <div className="level-left">
                        <div className="level-item">
                          <Link
                            to="/login"
                            className="help is-link has-text-link"
                          >
                            Login
                          </Link>
                        </div>
                      </div>
                      <div className="level-right">
                        <div className="level-item">
                          <Link
                            to="/forget"
                            className="help is-link has-text-link"
                          >
                            Forget password
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
