import HomeLogo from "../media/homelogo.png";
// import DemoVideo from "../media/demo.mp4";
import { useEffect } from "react";
// import Video from "./video";
import Footer from "./footer";
import Navbar from "../nav/navbar";
import { Link } from "react-router-dom";
import YoutubeEmbed from "./YoutubeEmbed";

export default function HomePage() {
  useEffect(() => {
    document.title = "Sheet Rules";
  });
  return (
    <>
      <div className="hero-head">{<Navbar />}</div>
      <section className="container mt-6">
        <div className="columns">
          <div className="column has-text-centered is-fullwidth">
            <img
              src={HomeLogo}
              style={{
                width: "140px",
                height: "140px",
              }}
              alt="logo"
            ></img>
            <h1 className="title is-1">Sheet Rules</h1>
            <h2 className="subtitle is-4 mt-3">
              Custom your google spreadsheet notification rules to your slack
            </h2>
          </div>
        </div>
        <div className="column mt-6 is-half has-text-centered is-offset-one-quarter">
          <h2 className="title is-2">Demo</h2>
        </div>
        <div className="column mt-6 is-half has-text-left is-offset-one-quarter">
          <h2 className="subtitle is-4">
            <strong># 1.Count numbers notification rule.</strong>
          </h2>
          <h3 className="subtitle is-5">How does it work?</h3>
          <p className="content">
            Set a notification counter to your sheet on our website
            dashboard.When the google sheet cells are edited and saved number
            greater than or equal to the count you set, it will trigger a slack
            notification.
          </p>
        </div>
        <div className="columns">
          <div className="column is-8 is-offset-2 mt-5">
            {/* {<Video src={DemoVideo} />} */}
            <YoutubeEmbed embedId="b42sMTLfPCs" />
          </div>
        </div>
        <div className="column mt-6 is-half has-text-left is-offset-one-quarter">
          <h2 className="subtitle is-4">
            <strong># 2.Duration rule notification.</strong>
          </h2>
          <h3 className="subtitle is-5">How does it work?</h3>
          <p className="content">
            Set a duration time worker to your sheet on our website dashboard.
            When the google sheet cells are edited and saved and after the
            duration of your config, it will send a slack notification.
          </p>
        </div>
        <div className="column mt-6 is-half has-text-left is-offset-one-quarter">
          <h2 className="subtitle is-4">
            <strong># 3.Specific time rule notification.</strong>
          </h2>
          <h3 className="subtitle is-5">How does it work?</h3>
          <p className="content">
            Set a specific time worker to your sheet on our website dashboard.
            When the google sheet cells are edited and saved. When the time is
            equal to your configuration time, it will send a slack notification.
          </p>
        </div>
        <div className="columns">
          <div className="column is-6 is-offset-3">
            <h2 className="title is-2 mt-6">FAQs</h2>
            <div className="field">
              <label className="subtitle is-4">How use this service?</label>
            </div>
            <p className="content">
              # 1.Install our add-on in google workspack. Just click{" "}
              {/* <strong>
                <Link to="">here</Link>
              </strong>{" "} */}
              to install addon to your spreadsheet.
            </p>
            <p className="content">
              # 2.Register account.{" "}
              <strong>
                {" "}
                <Link to="/signup">Click here</Link>{" "}
              </strong>
            </p>
            <p className="content">
              # 3.Click the plugin you install to your spreadsheet, and input
              your email account you register to our service.And click "Connect"
              button.When it will show success alert window.After that, you can
              config your rules in our website dashboard,{" "}
              <strong>
                {" "}
                <Link to="/dashboard">click here</Link>.
              </strong>
              (If you already have an account.)
            </p>
            <p className="content">
              # 4.Config a slack webhook url, and in dashboard save this webhook
              url.
            </p>
            <p className="content">
              # 5.In dashboard you can create new worker to your every sheet.
              When the rules are satisfied, it will send a slack notification to
              you.
            </p>
          </div>
        </div>
      </section>
      <div className="container mt-6"></div>
      <Footer />
    </>
  );
}
