import { Outlet, Link, useLocation } from "react-router-dom";
import React from "react";
import Navbar from "../nav/navbar";

export default function Dashboard() {
  const loaction = useLocation();
  let menuList = [
    { title: "Workers", path: "/dashboard/workers" },
    { title: "Contact", path: "/dashboard/contact" },
    //    { title: "Contact us", path: "/dashboard/contact-us" },
    { title: "Slack WebHook", path: "/dashboard/slack-webhook" },
    { title: "New Worker", path: "/dashboard/schedule/new" },
  ];

  // 获取当前选中的path
  function GetActivateClassName(path) {
    return loaction.pathname === path ? "is-active" : "";
  }

  return (
    <React.Fragment>
      <div className="hero-head">{<Navbar />}</div>
      <div
        className="container"
        style={{
          marginTop: "200px",
        }}
      >
        <div className="columns">
          <div className="column is-3">
            <aside className="menu">
              <p className="menu-label">General</p>
              <ul className="menu-list">
                {menuList.map((item) => {
                  return (
                    <li key={item.path}>
                      <Link
                        to={item.path}
                        className={GetActivateClassName(item.path)}
                      >
                        {item.title}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </aside>
          </div>
          <div className="column">
            <Outlet />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
