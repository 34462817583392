export default function Contact() {
  return (
    <div className="section is-medium has-text-centered">
      <h1 className="title is-5">
        Contact us at{" "}
        <a href="mailto:support@sheetrules.xyz">support@sheetrules.xyz</a>
      </h1>
    </div>
  );
}
