import * as React from "react";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

function ResponsiveTimePickers(props) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack spacing={3}>
        <TextField
          id="time"
          // label="Alarm clock"
          type="time"
          // defaultValue="07:30"
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            step: 300, // 5 min
          }}
          sx={{ width: 150 }}
          onChange={(e) => {
            props.update({ spec_value: e.target.value });
          }}
        />
      </Stack>
    </LocalizationProvider>
  );
}

// 0----

function NativePickers(props) {
  return (
    <Stack component="form" noValidate spacing={3}>
      <TextField
        id="datetime-local"
        label="Check time"
        type="datetime-local"
        // defaultValue="2017-05-24T10:30"
        sx={{ width: 250 }}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={(e) => {
          props.update({ spec_value: e.target.value });
        }}
      />
    </Stack>
  );
}

export default function SpecificTimeForm(props) {
  return (
    <>
      <div className="columns">
        <div className="column is-half">
          <div className="field">
            <label className="label">
              Config a specific tiime to check cells update
            </label>
            {/* <NativePickers {...props} /> */}
            <div className="control mt02">
              <ResponsiveTimePickers {...props} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
