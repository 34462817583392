export default function NumberForm(props) {
  return (
    <>
      <div className="columns">
        <div className="column is-half">
          <div className="field">
            <label className="label">Number changed notification</label>
            <input
              className="input"
              min={1}
              type={`number`}
              placeholder="input number"
              onChange={(e) => {
                props.update({ amount_count: e.target.value });
              }}
            />
            <p className="help has-text-grey">
              How many number cells changed to get a notification?
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
