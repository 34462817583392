import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { Li, toaster } from "evergreen-ui";
import LockIcon from "@mui/icons-material/Lock";

export default function ForgetPassword(props) {
  let navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [loading, setLoading] = useState("");

  useEffect(function (props) {
    document.title = "Forget Password";
  });
  function ForgetEvent(e) {
    console.log(`username=${userName}`);

    if (userName.length === 0) {
      toaster.danger("Email address can not be blank~", { duration: 2 });
      return;
    }

    let data = { email: userName };
    setLoading("is-loading");
    axios.post("/api/reset/password", data).then(function (response) {
      setLoading("");
      if (response["status"] === 200) {
        toaster.success(response["msg"], { duration: 2 });
        let timerID = setInterval(() => {
          navigate(`/verify?email=${userName}`);
          clearInterval(timerID);
        }, 2000);
      }
    });
  }

  return (
    <React.Fragment>
      <section
        className="hero is-fullheight"
        style={{
          // sea weed
          background:
            "linear-gradient(-225deg, #22E1FF 0%, #1D8FE1 48%, #625EB1 100%)",
        }}
      >
        <div className="hero-body">
          <div className="container">
            <div className="columns is-centered">
              <div className="column is-5-tablet is-4-desktop is-3-widescreen">
                <div className="box">
                  <div className="field">
                    <label className="label">Email</label>
                    <div className="control has-icons-left">
                      <input
                        className="input"
                        type="text"
                        placeholder="your email"
                        onChange={(e) => {
                          setUserName(e.target.value);
                        }}
                      />
                      <span className="icon is-small is-left">
                        <LockIcon />
                      </span>
                    </div>
                  </div>
                  <div className="field">
                    <button
                      className={`button is-rounded is-link is-fullwidth ${loading}`}
                      onClick={ForgetEvent}
                    >
                      Reset password
                    </button>
                    <div className="level">
                      <div className="level-left">
                        <div className="level-item">
                          <Link
                            to="/login"
                            className="help is-link has-text-link"
                          >
                            Login
                          </Link>
                        </div>
                      </div>
                      <div className="level-right">
                        <div className="level-item">
                          <Link
                            to="/signup"
                            className="help is-link has-text-link"
                          >
                            Sign up
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
