import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import "bulma/css/bulma.min.css";
import WorkerList from "./routes/dashboard/workerList";
import Dashboard from "./routes/dashboard/dashboard";
import Contact from "./routes/dashboard/contact";
import ContactUs from "./routes/dashboard/contactUs";
import ErrorPage from "./error-page";
import ScheduleInfo from "./routes/dashboard/schedule-info";
import DashboardIndex from "./routes/dashboard/dashboard-index";
import Edit from "./routes/dashboard/newWorker";
import axios from "axios";
import { toaster } from "evergreen-ui";
import SlackWebHook from "./routes/dashboard/slack-webhook";
// import SignInScreen from "./routes/user/signInScreen";
import HomePage from "./routes/home/home";
import Login from "./routes/user/login";
import Active from "./routes/user/active";
import NewPassword from "./routes/user/new_password";
import SignUp from "./routes/user/sign_up";
import ForgetPassword from "./routes/user/forget_password";
import Verify from "./routes/user/verify";
import Terms from "./routes/home/terms";
import Privacy from "./routes/home/privacy";
import SendMail from "./routes/mail/sendMain";
import Price from "./routes/price/price";

// 请求的拦截器
axios.defaults.baseURL = "/";
axios.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("token");
    // 判断请求的类型
    // 如果是post请求就把默认参数拼到data里面
    // 如果是get请求就拼到params里面
    if (config.method === "post") {
      config.data = {
        token: token,
        ...config.data,
      };
    } else if (config.method === "get") {
      config.params = {
        token: token,
        ...config.params,
      };
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// 添加响应拦截器
axios.interceptors.response.use(
  function (response) {
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    // console.log(response);
    // console.log(response["data"]["status"]);
    let status = response["data"]["status"];
    if (status === 201) {
      toaster.danger(response["data"]["msg"], { duration: 2 });
    } else if (status === 202) {
      // jump home
      window.location.href = "/";
    } else if (status === 203) {
      // uid error / illegal uid
      toaster.closeAll();
      localStorage.removeItem("token");
      window.location.href = "/";
    }
    return response.data;
  },
  function (error) {
    if (error.response.status >= 500) {
      toaster.warning("Server Error~", { duration: 2 });
    }

    return Promise.reject(error);
  }
);

// router
const router = createBrowserRouter([
  {
    path: "/",
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <HomePage />,
      },
      {
        path: "sendmail",
        element: <SendMail />,
      },
      {
        path: "price",
        element: <Price />,
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "active",
        element: <Active />,
      },
      {
        path: "forget",
        element: <ForgetPassword />,
      },
      {
        path: "new/password",
        element: <NewPassword />,
      },
      {
        path: "signup",
        element: <SignUp />,
      },
      {
        path: "/privacy-policy",
        element: <Privacy />,
      },
      {
        path: "/terms-of-service",
        element: <Terms />,
      },
      {
        path: "verify",
        element: <Verify />,
      },
      {
        path: "dashboard",
        element: <Dashboard />,
        children: [
          {
            index: true,
            element: <DashboardIndex />,
          },
          {
            path: "workers",
            element: <WorkerList />,
            // errorElement: <ErrorPage />,
          },
          {
            path: "workers/:type/:pk",
            element: <ScheduleInfo />,
          },
          {
            path: "contact",
            element: <Contact />,
          },
          {
            path: "contact-us",
            element: <ContactUs />,
          },
          {
            path: "slack-webhook",
            element: <SlackWebHook />,
          },
          {
            path: "schedule/new",
            element: <Edit />,
          },
        ],
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.Fragment>
    <RouterProvider router={router} />
  </React.Fragment>
  // <React.StrictMode>

  // </React.StrictMode>
);
