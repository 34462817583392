import axios from "axios";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toaster } from "evergreen-ui";
import HMTimePicker from "./timePicker";

export default function ScheduleInfo() {
  var nav = useNavigate();
  const [resu, setResu] = useState({});
  var para = useParams();
  useEffect(() => {
    console.log(para);
    var data = {
      type: para.type,
      pk: para.pk,
    };
    axios.post("api/user/worker", data).then((r) => {
      console.log(r.data);
      setResu(r.data);
    });
  }, []);

  const DeleteWorker = (e) => {
    axios.post("api/worker/delete", resu).then((r) => {
      console.log(r);
      if (r.status == 200) {
        toaster.success("Delete success!", { duration: 2 });
        setTimeout(() => {
          nav("/dashboard/workers");
        }, 2000);
      }
    });
  };

  const UpdateWorker = (e) => {
    axios.post("api/worker/update", resu).then((r) => {
      console.log(r);
      if (r.status == 200) {
        toaster.success("Update success!", { duration: 2 });
        setTimeout(() => {
          nav("/dashboard/workers");
        }, 2000);
      }
    });
    console.log(resu);
  };

  const AmountForm = () => {
    return (
      <>
        <div className="field">
          <div className="control">
            <input
              className="input"
              type="number"
              min="1"
              placeholder=""
              value={resu.count}
              onChange={(e) => {
                var tmp = { ...resu };
                tmp.count = e.target.value;
                console.log(tmp);
                setResu(tmp);
              }}
            />
          </div>
        </div>
      </>
    );
  };

  const DurationForm = () => {
    return (
      <>
        <div className="field-body">
          <div className="field">
            <p className="control is-expanded">
              <input
                className="input"
                type="number"
                min="1"
                placeholder="Hour"
                value={resu.hour}
                onChange={(e) => {
                  var tmp = { ...resu };
                  tmp.hour = e.target.value;
                  setResu(tmp);
                }}
              />
            </p>
          </div>
          <div className="field">
            <p className="control is-expanded">
              <input
                className="input"
                type="number"
                min="1"
                placeholder="minute"
                value={resu.minute}
                onChange={(e) => {
                  var tmp = { ...resu };
                  tmp.minute = e.target.value;
                  setResu(tmp);
                }}
              />
            </p>
          </div>
        </div>
      </>
    );
  };

  const UpdateTimeCallback = (d) => {
    // {spec_value: '02:58'}
    console.log(d);
    var hour = d.spec_value.split(":")[0];
    var minute = d.spec_value.split(":")[1];
    var tmp = { ...resu };
    tmp.hour = hour;
    tmp.minute = minute;
    setResu(tmp);
  };

  const SpecificForm = () => {
    return (
      <>
        <HMTimePicker
          update={UpdateTimeCallback}
          hour={parseInt(resu.hour)}
          minute={parseInt(resu.minute)}
        />
      </>
    );
  };

  const ShowComponent = () => {
    if (resu.type === "duration") {
      return <DurationForm />;
    } else if (resu.type === "amount") {
      return <AmountForm />;
    } else if (resu.type === "specific") {
      return <SpecificForm />;
    }
  };

  return (
    <>
      <div className="columns">
        <div className="column is-6">
          <label className="label">
            {resu.name} <p className="tag is-warning">{para.type} worker</p>
          </label>
          <div className="field">{ShowComponent()}</div>

          <div className="field">
            <div className="buttons">
              <button className="button is-success" onClick={UpdateWorker}>
                Save
              </button>
              <button className="button is-danger" onClick={DeleteWorker}>
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
