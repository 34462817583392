export default function DurationForm(props) {
  return (
    <div className="columns">
      <div className="column is-half">
        {/* <div className="field">
          <label className="label">Duration time(mins)</label>
          <input
            className="input"
            min="1"
            type={`number`}
            placeholder="input number"
            onChange={(e) => {
              props.update({ type: "duration", value: e.target.value });
            }}
          />
          <p className="help has-text-grey">How often to check for updates.</p>
        </div> */}
        <div className="field is-horizontal">
          {/* <div className="field-label is-normal">
            <label className="label">From</label>
          </div> */}
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded">
                <input
                  className="input"
                  type="number"
                  min="1"
                  placeholder="Hour"
                  onChange={(e) => {
                    props.update({ hour: e.target.value });
                  }}
                />
              </p>
            </div>
            <div className="field">
              <p className="control is-expanded">
                <input
                  className="input"
                  type="number"
                  min="1"
                  placeholder="minute"
                  onChange={(e) => {
                    props.update({ minute: e.target.value });
                  }}
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
