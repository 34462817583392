import { Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import DurationForm from "./duration_form";
import NumberForm from "./number_form";
import SpecificTimeForm from "./specific_time_form";
import { toaster } from "evergreen-ui";
import axios from "axios";

export default function Edit() {
  let navigate = useNavigate();

  const [formType, setFormType] = useState("");
  const [formData, setFormData] = useState(null);
  const [sheetNames, setSheetNames] = useState([]);

  useEffect(() => {
    axios.post("/api/user/sheets").then((r) => {
      console.log(r["data"]);
      setSheetNames(r["data"]);
    });
  }, []);

  const UpdateData = (d) => {
    // set key value to new obj
    var newFormdata = formData;
    newFormdata ??= {};

    Object.keys(d).forEach(function (key) {
      newFormdata[key] = d[key];
    });

    console.log(newFormdata);
    setFormData(newFormdata);
  };

  function changeTimeZone(date, timeZone) {
    if (typeof date === "string") {
      return new Date(
        new Date(date).toLocaleString("en-US", {
          timeZone,
        })
      );
    }

    return new Date(
      date.toLocaleString("en-US", {
        timeZone,
      })
    );
  }

  // const laDate = changeTimeZone(new Date(), "America/Los_Angeles");
  // console.log(`latime: ${laDate}`);
  // const cnDate = changeTimeZone(new Date(), "Asia/Shanghai");
  // console.log(`cntime: ${cnDate}`);

  const SaveForm = () => {
    console.log(`commit data ${formData}`);
    // api/worker/save
    if (!formData) {
      toaster.warning("Please input all the filed~");
      return;
    }

    axios
      .post("/api/worker/save", formData)
      .then((r) => {
        if (r.status === 200) {
          toaster.success("Save success", { duration: 1.5 });
          setTimeout(() => {
            navigate("/dashboard/workers");
          }, 1500);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <>
      <div className="field">
        <label className="label">Spreadsheet</label>
        <div className="control">
          <div className="select">
            <select
              onChange={(e) => {
                var optionElement = e.target.childNodes[e.target.selectedIndex];
                var sp_id = optionElement.getAttribute("sp_id");
                var sh_id = optionElement.getAttribute("sh_id");
                UpdateData({ sp_id: sp_id, sh_id: sh_id });
              }}
            >
              <option key="select">-- Select --</option>
              {sheetNames.map((item, idx) => {
                return (
                  <option key={idx} sp_id={item.sp_id} sh_id={item.sh_id}>
                    {item.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </div>
      <div className="field">
        <label className="label">Schedule Type</label>
        <div className="control">
          <div className="select">
            <select
              onChange={(e) => {
                var index = e.target.selectedIndex;
                var optionElement = e.target.childNodes[index];
                // var option = optionElement.getAttribute("data-id");
                // console.log(`index = ${index}; option data-id = ${option}`);
                // setFormType(`${index}`);
                var dataId = optionElement.getAttribute("data-id");
                console.log(dataId);
                if (dataId === "0") {
                  UpdateData({ type: "" });
                  setFormType("");
                }
                if (dataId === "1") {
                  UpdateData({ type: "duration" });
                  setFormType(<DurationForm update={UpdateData} />);
                }
                if (dataId === "2") {
                  UpdateData({ type: "specific" });
                  setFormType(<SpecificTimeForm update={UpdateData} />);
                }
                if (dataId === "3") {
                  UpdateData({ type: "amount" });
                  setFormType(<NumberForm update={UpdateData} />);
                }
              }}
            >
              <option data-id="0">Select Notification Type</option>
              {/* <option data-id="1">
                Every Duration Notification(If have data changed)
              </option> */}
              {/* <option data-id="2">
                Specific Time Notification(If have data changed)
              </option> */}
              <option data-id="3">
                Amount Changed Notification(If have data changed)
              </option>
            </select>
          </div>
        </div>
      </div>

      {formType}
      <div className="buttons">
        <button className="button is-success" onClick={SaveForm}>
          Save
        </button>
        <Link to="/dashboard/workers" className="button is-danger">
          Cancel
        </Link>
      </div>
    </>
  );
}
