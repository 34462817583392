import axios from "axios";
import { useState } from "react";
import { toaster } from "evergreen-ui";

export default function ContactUs() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [buttonStatus, setButtonStatus] = useState("button is-success");

  const commit = (e) => {
    if (!name || !email || !subject || !message) {
      toaster.notify("Please enter all field 😄~");
      return;
    }
    var data = {
      name: name,
      email: email,
      subject: subject,
      message: message,
    };
    setButtonStatus("button is-success is-loading");
    axios.post("/api/feedback", data).then((r) => {
      setButtonStatus("button is-success");
      if (r.status === 200) {
        toaster.success(r.msg, { duration: 2 });
        setSubject("");
        setMessage("");
      }
    });
  };
  return (
    <div className="box">
      <div className="hero">
        <div className="hero-body">
          <p className="title has-text-centered">Contact us</p>
          <div className="field">
            <label className="label">Name</label>
            <input
              type="text"
              className="input"
              placeholder="Full Name"
              value={name}
              required
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </div>
          <div className="field">
            <label className="label">Email Address</label>
            <input
              type="text"
              required
              className="input"
              placeholder="Email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </div>
          <div className="field">
            <label className="label">Subject</label>
            <input
              required
              type="text"
              className="input"
              placeholder="Enter subject"
              value={subject}
              onChange={(e) => {
                setSubject(e.target.value);
              }}
            />
          </div>
          <div className="field">
            <label className="label">Message</label>
            <textarea
              required
              className="textarea"
              placeholder="Type your message..."
              value={message}
              onChange={(e) => {
                setMessage(e.target.value);
              }}
            />
          </div>
          <div className="field">
            <div className="control">
              <button className={buttonStatus} onClick={commit}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
