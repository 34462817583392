import { useRouteError } from "react-router-dom";

export default function ErrorPage() {
  const error = useRouteError();
  return (
    <>
      <section className="hero is-large">
        <div className="hero-body has-text-centered">
          <p className="title">Opps</p>
          <p className="subtitle is-6" style={{ marginTop: "45px" }}>
            Sorry, an unexpected error has occurred.
          </p>
          <p>
            <i>{error.statusText || error.message}</i>
          </p>
        </div>
      </section>
    </>
  );
}
