import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { toaster } from "evergreen-ui";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";

export default function Login(props) {
  let navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState("");
  useEffect(function (props) {
    document.title = "Login";
  });
  function login(e) {
    // console.log(`email=${email}, password= ${password}`);
    let data = { email: email, password: password };
    // verify blank
    if (email.length === 0 || password.length === 0) {
      toaster.warning("Email or password can't be blank 😁", {
        duration: 2,
      });
      return;
    }

    setLoading("is-loading");
    axios
      .post("/api/login", data)
      .then(function (response) {
        // console.log(response);
        setLoading("");
        if (response["status"] === 200) {
          localStorage.setItem("token", response["token"]);
          toaster.success("Login success", {
            duration: 1.5,
          });
          setTimeout(() => {
            navigate("/");
          }, 1500);
        } else {
          toaster.warning(response["msg"], {
            duration: 2,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        toaster.danger("Server error", { duration: 2 });
        setLoading("");
      });
  }

  return (
    <React.Fragment>
      <section
        className="hero is-fullheight"
        style={{
          // 绿色 green beach
          //   background: "linear-gradient(to right, #02aab0, #00cdac)",
          //   emerald water
          //   background: "linear-gradient(to right, #348f50, #56b4d3)",
          //   mojito
          //   background: "linear-gradient(to right, #1d976c, #93f9b9)",
          // sea weed
          background: "linear-gradient(to right, #4cb8c4, #3cd3ad)",
          //   sea blizz
          //   background: "linear-gradient(to right, #1cd8d2, #93edc7)",
          //   蓝色 skyline
          //   background: "linear-gradient(to right, #1488cc, #2b32b2)",
        }}
      >
        <div className="hero-body">
          <div className="container">
            <div className="columns is-centered">
              <div className="column is-5-tablet is-4-desktop is-3-widescreen">
                <div className="box">
                  <div className="field">
                    <label className="label">Email</label>
                    <div className="control has-icons-left">
                      <input
                        className="input"
                        type="text"
                        placeholder="your email"
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                      <span className="icon is-small is-left">
                        {/* <i className="fa fa-user"></i> */}
                        <EmailIcon />
                      </span>
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Password</label>
                    <div className="control has-icons-left">
                      <input
                        className="input"
                        type="password"
                        placeholder="your password"
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                      />
                      <span className="icon is-small is-left">
                        <LockIcon />
                      </span>
                    </div>
                  </div>
                  <div className="field">
                    <button
                      className={`button is-success is-rounded is-fullwidth ${loading}`}
                      onClick={login}
                    >
                      Login
                    </button>
                    <div className="level">
                      <div className="level-left">
                        <div className="level-item">
                          <Link
                            to="/forget"
                            className="help is-link has-text-link"
                          >
                            Forget password
                          </Link>
                        </div>
                      </div>
                      <div className="level-right">
                        <div className="level-item">
                          <Link
                            to="/signup"
                            className="help is-link has-text-link"
                          >
                            Sign up
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
