import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

var tableHeadTitles = ["Index", "Name", "Type"];

export default function WorkerList() {
  const [dataArray, setDataArray] = useState([]);

  useEffect(() => {
    axios.post("/api/user/workers").then((r) => {
      console.log(r);
      var countworker_objs = r.data.countworker_objs;
      var duration_objs = r.data.duration_objs;
      var specific_objs = r.data.specific_objs;
      var arr = countworker_objs.concat(duration_objs, specific_objs);
      setDataArray(arr);
    });
  }, []);

  return (
    <>
      <table className="table is-fullwidth">
        <thead>
          <tr>
            {tableHeadTitles.map((item, idx) => {
              return <th key={idx}>{item}</th>;
            })}
          </tr>
        </thead>
        <tfoot>
          <tr>
            {tableHeadTitles.map((item, idx) => {
              return <th key={idx}>{item}</th>;
            })}
          </tr>
        </tfoot>
        <tbody>
          {dataArray.map((item, index) => {
            return (
              <tr key={index}>
                <th>{index + 1}</th>
                {/* workers/:spid/:shid */}
                <td>
                  <Link to={item.type + "/" + item.pk}>{item.name}</Link>
                </td>
                <td>{item.type}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {/* <nav className="pagination" role="navigation" aria-label="pagination">
        <a
          className="pagination-previous"
          onClick={() => {
            throw new Error("这里啥都没有");
            alert("Previous");
          }}
        >
          Previous
        </a>
        <a
          className="pagination-next"
          onClick={() => {
            alert("Next");
          }}
        >
          Next page
        </a>
        <ul className="pagination-list">
          <li>
            <a className="pagination-link" aria-label="Goto page 1">
              1
            </a>
          </li>
          <li>
            <span className="pagination-ellipsis">&hellip;</span>
          </li>
          <li>
            <a className="pagination-link" aria-label="Goto page 45">
              45
            </a>
          </li>
          <li>
            <a
              className="pagination-link is-current"
              aria-label="Page 46"
              aria-current="page"
            >
              46
            </a>
          </li>
          <li>
            <a className="pagination-link" aria-label="Goto page 47">
              47
            </a>
          </li>
          <li>
            <span className="pagination-ellipsis">&hellip;</span>
          </li>
          <li>
            <a className="pagination-link" aria-label="Goto page 86">
              86
            </a>
          </li>
        </ul>
      </nav> */}
    </>
  );
}
