import React, { useEffect, useState } from "react";
import axios from "axios";
import { toaster } from "evergreen-ui";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import TextFieldsIcon from "@mui/icons-material/TextFields";

export default function SendMail(props) {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState("");
  const [subject, setSubject] = useState("");
  const [content, setContent] = useState("");

  useEffect(function (props) {
    document.title = "SendMail";
  }, []);
  const send = (e) => {
    var data = {
      email: email,
      content: content,
      subject: subject,
    };
    console.log(data);
    // verify blank
    if (email.length === 0 || subject.length === 0 || content.length === 0) {
      toaster.warning("Email or password or content can't be blank 😁", {
        duration: 2,
      });
      return;
    }

    setLoading("is-loading");

    axios
      .post("/api/mail/send", data)
      .then((r) => {
        if (r.status === 200) {
          setLoading("");
          toaster.success(r.msg, { duration: 2 });
          setEmail("");
          setContent("");
          setSubject("");
        }
      })
      .catch((e) => {
        console.log(e);
        toaster.danger("Server error", { duration: 2 });
        setLoading("");
      });
  };

  return (
    <React.Fragment>
      <section
        className="hero is-fullheight"
        style={{
          // 绿色 green beach
          //   background: "linear-gradient(to right, #02aab0, #00cdac)",
          //   emerald water
          //   background: "linear-gradient(to right, #348f50, #56b4d3)",
          //   mojito
          //   background: "linear-gradient(to right, #1d976c, #93f9b9)",
          // sea weed
          background: "linear-gradient(to right, #4cb8c4, #3cd3ad)",
          //   sea blizz
          //   background: "linear-gradient(to right, #1cd8d2, #93edc7)",
          //   蓝色 skyline
          //   background: "linear-gradient(to right, #1488cc, #2b32b2)",
        }}
      >
        <div className="hero-body">
          <div className="container">
            <div className="columns is-centered">
              <div className="column is-5">
                <div className="box">
                  <div className="field">
                    <label className="label">Email</label>
                    <div className="control has-icons-left">
                      <input
                        className="input"
                        type="text"
                        placeholder="Recipient email"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                      <span className="icon is-small is-left">
                        {/* <i className="fa fa-user"></i> */}
                        <EmailIcon />
                      </span>
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Subject</label>
                    <div className="control has-icons-left">
                      <input
                        className="input"
                        type="text"
                        placeholder="Input subject"
                        value={subject}
                        onChange={(e) => {
                          setSubject(e.target.value);
                        }}
                      />
                      <span className="icon is-small is-left">
                        <TextFieldsIcon />
                      </span>
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Content</label>
                    <div className="control">
                      <textarea
                        className="textarea"
                        placeholder="Input your content"
                        rows="8"
                        value={content}
                        onChange={(e) => {
                          setContent(e.target.value);
                        }}
                      ></textarea>
                    </div>
                  </div>
                  <div className="field">
                    <button
                      className={`button is-success is-rounded is-fullwidth ${loading}`}
                      onClick={send}
                    >
                      Send
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
