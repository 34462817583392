import { useState, useEffect } from "react";
import axios from "axios";
import { toaster } from "evergreen-ui";

export default function SlackWebHook() {
  const [hookURL, setHookURL] = useState("");

  useEffect(() => {
    axios.post("/api/user/hookurl").then((r) => {
      // console.log(r);
      setHookURL((r["data"] ??= ""));
    });
  }, []);

  //
  const SaveHookUrl = (e) => {
    console.log(hookURL);
    axios.post("/api/save/hook", { url: hookURL }).then((r) => {
      toaster.success("Save success~", { duration: 1.2 });
    });
  };
  return (
    <div className="columns">
      <div className="column is-6">
        <div className="field">
          <label className="label">Slack WebHook Url</label>
          <div className="control">
            <input
              className="input"
              type="text"
              placeholder="Input your slack webhook url"
              value={hookURL}
              onChange={(e) => {
                setHookURL(e.target.value);
              }}
            ></input>
          </div>
        </div>
        <div className="field">
          <div className="control">
            <button className="button is-primary" onClick={SaveHookUrl}>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
