import React, { useState, useEffect } from "react";
import axios from "axios";
import { searchParams, useSearchParams, useNavigate } from "react-router-dom";
import { toaster } from "evergreen-ui";

export default function NewPassword(props) {
  let navigate = useNavigate();
  const [loading, setLoading] = useState("");
  const [password, setPassword] = useState("");
  let [searchParams, setSearchParams] = useSearchParams();
  let token = searchParams.get("token") || "";
  let email = searchParams.get("email") || "";
  function Confirm() {
    let data = { token: token, email: email, password: password };
    setLoading("is-loading");
    axios.post("/api/new/password", data).then((response) => {
      setLoading("");
      console.log(response);
      if (response["status"] === 200) {
        toaster.success(response["msg"], { duration: 2 });
        let timerID = setInterval(() => {
          navigate("/login");
          clearInterval(timerID);
        }, 2000);
      }
    });
  }
  return (
    <React.Fragment>
      <section
        className="hero is-fullheight"
        style={{
          // sea weed
          background:
            "linear-gradient(-225deg, #22E1FF 0%, #1D8FE1 48%, #625EB1 100%)",
        }}
      >
        <div className="hero-body">
          <div className="container">
            <div className="columns is-centered">
              <div className="column is-4">
                <div className="field">
                  <label className="label has-text-white">
                    Enter the new password
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      type="text"
                      placeholder="Input new password"
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="field">
                  <div className="control">
                    <button
                      onClick={Confirm}
                      className={`button is-link  is-rounded is-fullwidth ${loading}`}
                    >
                      Change Password
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
