import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";

export default function HMTimePicker(props) {
  var hour = props.hour < 10 ? `0${props.hour}` : props.hour;
  var minute = props.minute < 10 ? `0${props.minute}` : props.minute;

  console.log(hour);
  console.log(minute);
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack spacing={3}>
        <TextField
          id="time"
          type="time"
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            step: 300, // 5 min
          }}
          sx={{ width: 150 }}
          defaultValue={`${hour}:${minute}`}
          onChange={(e) => {
            props.update({ spec_value: e.target.value });
          }}
        />
      </Stack>
    </LocalizationProvider>
  );
}
