import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { toaster } from "evergreen-ui";
import HomeLogo from "../media/homelogo.png";

export default function Navbar(props) {
  // 路由
  let navigate = useNavigate();
  // 汉堡包
  const [isBurgerActive, setIsBurgerActive] = useState(false);
  const [email, setEmail] = useState(null);
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token === null) {
      setEmail("");
      return;
    }
    // console.log("reload again");
    axios
      .post("/api/user/info", {})
      .then(function (response) {
        if (response["status"] === 200) {
          setEmail(response["email"]);
        }
        // console.log(response);
      })
      .catch((err) => {
        // toaster.danger(
        //   "Server error, please try again or contact ",
        //   { duration: 4 }
        // );
      });
  }, []);

  let UserIcon = () => {
    return (
      <React.Fragment>
        <div className="navbar-item has-dropdown is-hoverable">
          <a
            className="navbar-link"
            style={{
              fontWeight: "700",
              lineHeight: "1.1",
            }}
          >
            {email}
          </a>
          <div className="navbar-dropdown">
            <Link
              to="/dashboard"
              className="navbar-item"
              onClick={() => {
                setIsBurgerActive(false);
              }}
            >
              Dashboard
            </Link>
            {/* <Link
              to="/dashboard"
              className="navbar-item"
              onClick={() => {
                setIsBurgerActive(false);
              }}
            >
              Dashboard
            </Link> */}
            <hr className="navbar-divider"></hr>
            <Link
              to="/"
              className="navbar-item"
              onClick={() => {
                setIsBurgerActive(false);
                localStorage.removeItem("token");
                setEmail("");
              }}
            >
              Logout
            </Link>
          </div>
        </div>
      </React.Fragment>
    );
  };

  let GetStarted = () => {
    return (
      <React.Fragment>
        <div className="navbar-item">
          <div className="buttons">
            <Link to="/login" className="button is-link" onClick={() => {}}>
              Get Started
            </Link>
          </div>
        </div>
      </React.Fragment>
    );
  };

  function getShowView() {
    if (email === null) {
      return <React.Fragment></React.Fragment>;
    } else if (email.length === 0) {
      return <GetStarted />;
    } else {
      return <UserIcon />;
    }
  }

  return (
    <React.Fragment>
      <nav
        className="navbar is-fixed-top has-background-white"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="container">
          <div className="navbar-brand">
            <Link className="navbar-item" to="/">
              <img
                // src={require("../media/mailchimp.png")}
                // width="28"
                // height="28"
                src={HomeLogo}
                width="28"
                height="28"
              ></img>
            </Link>

            <a
              onClick={() => {
                setIsBurgerActive(!isBurgerActive);
              }}
              role="button"
              className={`navbar-burger burger ${
                isBurgerActive ? "is-active" : ""
              }`}
              aria-label="menu"
              aria-expanded="false"
              data-target="navbarBasicExample"
            >
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </a>
          </div>
          <div
            className={`navbar-menu ${isBurgerActive ? "is-active" : ""}`}
            id="navbarBasicExample"
          >
            <div className="navbar-start">
              {/* <Link
                to="/"
                className="navbar-item"
                onClick={() => {
                  setIsBurgerActive(false);
                }}
              >
                Home
              </Link>
              <Link
                to="/signup"
                className="navbar-item"
                onClick={() => {
                  setIsBurgerActive(false);
                }}
              >
                Home2
              </Link> */}
            </div>
            <div className="navbar-end">
              {/* <Link
                to="/price"
                className="navbar-item"
                style={{
                  fontWeight: "700",
                  lineHeight: "1.1",
                }}
                onClick={() => {}}
              >
                Pricing
              </Link> */}

              {/* <GetStarted /> */}
              <div className="navbar-item">
                <Link to="/price">
                  <strong>Pricing</strong>
                </Link>
              </div>
              {getShowView()}
            </div>
          </div>
        </div>
      </nav>
    </React.Fragment>
  );
}
